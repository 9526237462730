import { Toast } from 'vant';
import { userReport } from '@/service/moudules/wrapApi';
export function isRegister(obj, msg) {
    try {
        if (obj['certificationStatus'] !== '') {
            switch (obj.certificationStatus) {
                case 'YES':
                    Toast.success('您已经认证过,正在为您跳转,请稍后');
                    let uParams = {
                        no: 1,
                        bt: 1,
                        ot: new Date().getTime() / 1000,
                        pi: obj.authenticationResp
                    };

                    let arr = []

                    arr.push(uParams)

                    let paramsArr = {
                        dataList: arr,
                    };
                    userReport(paramsArr);
                    setTimeout(() => {
                        window.location.href = `https://sdk.sh9130.com/partner/h5go/754/tjqy_tjqylch5lj_ATK/?userid=${obj.id}`
                    }, 1500)
                    return;
                case 'NO':
                    Toast.fail('您还未实名认证,请先实名认证后再开始游戏');
                    this.$router.push({
                        path: '/authentication',
                        query: {
                            id: obj.id,
                            pis: obj.authenticationResp
                        },
                    });

                    return;
                case 'UNDERY_WAY':
                    Toast('认证中,请稍后 请勿重复点击认证按钮!');
                    break;

                default:
                    break;
            }
        } else {
            Toast.fail(msg);
        }
    } catch (error) {
        console.log(error);
    }
}