/**
 * 
 * 用户支付宝H5支付(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const h5Pay = params => axios.post('/aliPay/create/oder', params).then(data => data)


/**
 * 
 * 用户上报行为(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const userReport = params => axios.post('/authentication/collection/behavior', params).then(data => data)



/**
 * 
 * 支付宝支付订单查询(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const aliPayQuery = params => axios.post('/aliPay/query/status', params).then(data => data)



/**
 * 
 * 微信支付订单查询(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const wechatPayQuery = params => axios.post('/wxPay/query/status', params).then(data => data)


/**
 * 
 * 支付结果回调(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const payCallback = params => axios.post(`https://sdk.sh9130.com/partner/pay/754/tjqy_tjqylch5lj_ATK/?orderId=${params.orderId}&orderNo=${params.orderNo}&amount=${params.amount}&uid=${params.uid}&orderStatus=${params.orderStatus}`).then(data => data)




/**
 * 
 * 微信预下单(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const h5Wechat = params => axios.post('/wxPay/create/oder',params).then(data => data)