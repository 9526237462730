<template>
  <div>
    <div class="warp-phone-bg">
      <div class="warp-phone-box">
        <div class="box-header">
          <div class="box-title">账号登录</div>
          <back-arrow></back-arrow>
        </div>
        <div class="phone-box">
          <div class="box-content-login">
            <van-field
              class="login-num"
              v-model="account.username"
              label-width="25px"
              required
              clearable
              label="账号:"
              :placeholder="userPatternMessage"
            />
          </div>
          <div class="box-content-code">
            <van-field
              class="login-code"
              v-model="account.password"
              type="password"
              label-width="25px"
              clearable
              required
              label="密码:"
              :placeholder="passwordPatternMessage"
            />
          </div>
        </div>
        <div class="rule">
          <div class="rule-content">
            <van-checkbox
              shape="square"
              v-model="accountCheck"
              icon-size="16"
              class="rule-check"
            ></van-checkbox>
            <div class="rule-agreement-read">
              <span>我已阅读并同意</span>
              <span @click="phoneUserProcotol">用户协议</span>
              <span>和</span>
              <span @click="phonePrivacyPolicy">隐私政策</span>
            </div>
          </div>
        </div>
        <div class="box-content-code-login">
          <button @click="onSubmitAccount" class="box-content-code-login-btn">
            <span class="box-content-span">登录</span>
          </button>
        </div>
        <div class="rule-agreement">
          <span>想要账号更安全?建议使用</span>
          <span @click="phoneRegister">手机注册</span>
        </div>
        <div class="box-content-other-login">
          <button @click="quickOtherLogin" class="box-content-other-login-btn">
            <span class="box-content-span"
              >其他登录方式 <van-icon name="arrow" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userLogin } from '@/service/moudules/wrapLogin';
import { getLocalStorage } from '@/util/userLoginFn';
import backArrow from './components/backArrow.vue';
import { isRegister } from '@/util/certification';
import { Toast } from 'vant';
export default {
  components: {
    backArrow,
  },
  data() {
    return {
      userLogin,
      isRegister,
      getLocalStorage,
      accountCheck: false,
      countdown: 60,
      isCode: false,
      account: {
        username: '',
        password: '',
      },
      userPattern: /^[A-Za-z0-9]{6,12}$/,
      userPatternMessage: '请输入(6~12个字母或数字)',
      passwordPatternMessage: '请输入(6~12个字母或数字)',
      usernameReg: /^[a-zA-Z0-9]{4,16}$/,
      passwordReg: /^[a-zA-Z0-9]{4,16}$/,
      inviteCode: '',
    };
  },
  methods: {
    // userNameValidator(val) {
    //   if (val === '') {
    //     this.userPatternMessage = '请输入账号';
    //     return false;
    //   }
    //   if (this.userPattern.test(val)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // passwordValidator(val) {
    //   if (val === '') {
    //     this.passwordPatternMessage = '请输入密码';
    //     return false;
    //   }
    //   if (this.userPattern.test(val)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    backArrow() {
      this.$router.push({
        path: '/wrapLogin',
      });
    },
    phoneRegister() {
      this.$router.push({
        path: '/phone',
      });
    },
    quickOtherLogin() {
      this.$router.push({
        path: `/wrapLogin?inviteCode=${this.getLocalStorage('inviteCode')}`,
      });
    },
    phoneUserProcotol() {
      console.log(this.$route, 'route');
      this.$router.push({
        path: '/userProcotol',
      });
    },
    phonePrivacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy',
      });
    },

    userOtherLogin() {},
    async onSubmitAccount() {
      try {
        let { username, password } = this.account;
        if (username === '' && password === '') {
          Toast.fail('请先填写账号密码');
          return false;
        }

        if (!this.usernameReg.test(username)) {
          Toast.fail('账号不符合规则');
          return false;
        }
        if (!this.passwordReg.test(password)) {
          Toast.fail('密码不符合规则');
          return false;
        }
        if (!this.accountCheck) {
          Toast.fail('请先阅读并同意用户协议');
          return false;
        }

        let params = {
          account: username,
          password: password,
        };

        let { data, code, msg } = await userLogin(params);

        if (code !== 200) {
          Toast.fail('账号登录失败,请检查');
          this.account.username = '';
          this.account.password = '';
          return false;
        }

        Toast.success('登录成功');
        this.timer = setTimeout(() => {
          this.isRegister(data, msg);
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.inviteCode = this.getLocalStorage('inviteCode');
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>
<style lang="less" scoped>
.warp-phone-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .warp-phone-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 345px;
    height: 390px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    // backdrop-filter: grayscale(60%);
    .box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-title {
        position: relative;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
      }
      .el-icon-question {
        position: absolute;
        top: 23px;
        right: 20px;
        font-size: 18px;
        color: #000;
      }
      .account-arrow {
        position: absolute;
        left: 23px;
        top: 23px;
        font-size: 20px;
        color: #000;
      }
    }
    .rule-agreement {
      margin-top: 10px;
      color: #000;
      font-size: 14px;
      font-size: 14px;
      font-family: PingFang-SC-Medium;
      & :nth-child(2) {
        color: #815fe7;
        font-size: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium;
      }
    }
    .phone-box {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .box-content-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;

        .login-num {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
        }
      }
      .box-content-code {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;
        margin: 20px 0 0 4px;
        .login-code {
          overflow-wrap: break-word;
          color: #fff;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          .push-code {
            color: #7351e9;
          }
        }
      }
    }
    .rule {
      margin-top: 20px;
      .rule-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .rule-check {
          margin-right: 10px;
        }
        .rule-agreement-read {
          color: #000;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
          & :nth-child(2) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
          & :nth-child(4) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
        }

        .policy {
        }
      }
    }
    .box-content-code-login {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-content-code-login-btn {
        border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        background-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        text-align: center;
        border-radius: 23px;
        border: 2px solid #5266c9;
        margin-top: 24px;
        padding: 6px 90px 6px 90px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #fff;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          white-space: nowrap;
          line-height: 25px;
        }
      }
    }
    .box-content-other-login {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-content-other-login-btn {
        text-align: center;
        border-radius: 23px;
        border: 2px solid #5266c9;
        margin-top: 24px;
        padding: 6px 62px 6px 62px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #7351e9;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          white-space: nowrap;
          line-height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.van-form {
  width: 100%;
}
</style>
